import { Carousel } from 'react-carousel-minimal';



function Couresel() {
 const data = [
    {
      image: "./img/img1.jpg",
      caption: "Seminar 2015"
    },
    {
      image: "./img/img2.jpg",
      caption: "Fr. Brian - Director Roman Archives"
    },
    {
      image: "./img/img3.jpg",
      caption: "Front View"
    },
    {
      image: "./img/img4.jpg",
      caption: "Sacred Heart College"
    },
    {
      image: "./img/img5.jpg",
      caption: "Archives inside "
    },
    {
      image: "./img/img6.jpg",
      caption: "Interior View"
    },
    {
      image: "./img/img7.jpg",
      caption: "JEMPARC"
    },
    {
      image: "./img/img1.jpg",
      caption: "Seminar 2015"
    },
    {
      image: "./img/img3.jpg",
      caption: "Front View"
    }
  ];

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }
  return (
    <div className="App" style={{paddingTop:"110px"}}>
      <div style={{ textAlign: "center" }}>
        <h2>Gallery</h2>
        
        <div style={{
          padding: "0 20px"
        }}>
          <Carousel
            data={data}
            time={1500}
            width="1350px"
            height="500px"
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            
            slideImageFit="cover"
            thumbnails={true}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxWidth: "850px",
              maxHeight: "500px",
              margin: "20px auto",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Couresel;

