import React, { Component } from 'react';


export default class Aboutus extends Component {
    render() {
        return (
            <React.Fragment>
                
                <section style={{paddingTop:"110px"}}>
                <br/>
                
                    <div className="container text-justify">
                        <div className="row">
<div className="col-sm-9">
    <br/><h1>About Us</h1>
    <div className="b">
    <p >
Jesuits! ar Jesuits are members of the Society of Jesus SJ,
one of the largest Religious Congregations for men in the Catholic Church. 
The Society of Jesus was founded by St. Ignatius of Loyola,
a Spanish soldier, who, like most men of his time, initially dreamed of bravery,
fame, wealth and women. After being wounded in a battle
he underwent a terrible surgery. While he was convalescing,     he took to reading the lives of Saints which  completely transformed him.
     He wanted to serve God - not the king.
For the greater glory of God Ad majorem Dei gloriam
became his motto. Jesuits travelled to all parts of the world to proclaim

the good news of Jesus. Realizing the vital role that education plays in 
humanizing the society, they founded Schools and Colleges and offered not 
merely academic education, but also discipline and  human values.
 Apart from education, they worked in various fields - science, communication,
  social action, philosophy and theology, spirituality... all for God's greater
   glory. <br/> <br/> <br/>

Today more than 16,000 Jesuits work in 112 countries in six continents,


 with the aim of moulding, with God’s grace, men and women for others. 
 
 
 They see themselves as converted sinners or wounded healers and understand 
 their mission in the world as service of faith and promotion of justice. 
 The present Superior general is the charming, serene Fr. Arturo Sosa SJ.
  To learn more about Jesuits worldwide, visit...
 <a href='http://www.sjweb.info/'>www.sjweb.info</a> </p>
</div>
</div>
<div className="col-sm-3">
<ul>
<h4>Other Links</h4><br/>
<li><a href='http://www.sjweb.info/'>Sjweb</a></li>
</ul>
<ul>
<li>< a href='http://www.sjweb.info/arsi/'>Archives Rome</a></li>
</ul>
</div>

                        </div>
                    </div>
                </section>
                </React.Fragment>
        );
    }
}
