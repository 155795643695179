import React, { Component } from 'react'

export default class Enquiry extends Component {
    render() {
        return (
            <React.Fragment>
            <h1> from Enquiery</h1>
            </React.Fragment>
        )
    }
}
