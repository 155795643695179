import React, { Component } from 'react';
import { Navbar,Container,Nav } from 'react-bootstrap';

export default class Navebar extends Component {
    render() {
        return (
          
            <Navbar collapseOnSelect  expand="lg"  variant="dark">

<Container >
  <div>
  <Navbar.Brand href="./" style={{padding:"5px"}} >
    <img 
        src="./img/arlogonew.png"
        width="100"
        height="90"
        alt="logo"  
        />{' '}    
       <span style={{padding:"20px", marginTop:"20px", fontSize:"32px"}}> JEMPARC </span> </Navbar.Brand> 
    </div>
    <br/>
      <div>
  <Navbar.Toggle aria-controls="responsive-navbar-nav"align="center" />
  <Navbar.Collapse id="responsive-navbar-nav"  >
    <Nav className="navbar-nav" align="right">
      <Nav.Link href="./Aboutus">Aboutus</Nav.Link>
      <Nav.Link href="./ImageGallery">ImageGallery</Nav.Link>
      <Nav.Link href="./Research">Research</Nav.Link>
      <Nav.Link href="./Materials">Materials</Nav.Link>
      <Nav.Link href="./ApplicationForm">ApplicationForm</Nav.Link>
      <Nav.Link href="./ContactUs">ContactUs</Nav.Link>
    </Nav>
  </Navbar.Collapse>
  </div>
  </Container>
</Navbar>
        )
    }
}
