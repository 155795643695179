import React, { Component } from 'react'
import Marquee from 'react-fast-marquee'
import { Row,Col,Container } from 'react-bootstrap';
import News from './News';

export default class Home extends Component {
    render() {
        return (
            <React.Fragment>
                     <div>
                 <Marquee speed="30" pauseOnHover="true"> *Please get prior appointment          *Working Hours: 9:00 AM - 12:30 PM ; 2:00 PM -5:00 PM ; Closed on Sundays and Public Holidays</Marquee>
              <img src="../img/fr1.jpg" hieght="200px" width="100%"  alt="Product">
             </img>
             
             </div>
             <Container>
  <Row>
    <Col sm={4} >
        <br/>
        <div class="text-center">
        
        <br/>
<img className="img-circle1"  src="./img/samy.jpg" alt="Director"></img>
<br/><br/> <u>
<h4>FR ANTO </h4></u> <br/>
<p >The Jesuit Archives of Madurai Province is situated at Shembaganur (Kodaikanal). 
It contains hundreds of manuscripts, diaries and official documents. 
Besides these primary sources it holds many studies and monographs which throw light on the missionary endeavours,
and on the socio-economic, cultural and historical facts.
Besides these, it serves as a storehouse of general knowledge in different languages.
At the subaltern level socio, economic, cultural, political history of Tamil Nadu could be called out from the missionary records.
 the missionaries mastered Tamil;
they composed dictionaries and grammars; they created christian literature in Tamil.
Fr. Beschi is honoured as the father of Tamil prose; Fr. Robert de Nobili changes in Tamil scripting.
</p>
</div>
    </Col>
    <Col sm={4}>
    <br/>
    <center><h3>History and Present Condition of Madurai Jesuit Province Archives</h3> </center>

    <br/><p>
It was only after the restoration of the Society of 
Jesus the archives began to take shape. In 1934 correspondences and diaries were stored at Trichy. The earliest and most substantial materials of the archives are related to the Jesuit Bishops Alexis Canoz, Jean Marie Barthe, Augustin Faisandier and other Jesuit bishops
 like Jean Peter Leonard and Tiburtius Roche. Till 1936 the documents preserved in the Cathedral residence of Jesuits at Tiruchirappalli were brought to Sacred Heart College(SHC), Shembaganur. 
This was because the Society of Jesus was handing over the Diocese of Trichinopoly to the local clergy.
Hence the province had the responsibility of preserving the documents pertaining to the Jesuits separately.
This they did in Shembaganur.
<br/><br/>
SHC started in 1895 and it housed the Jesuit scholastics, doing their spiritual formation (Novitiate), study of humanities (Juniorate), and Philosophy for the Madurai Mission. From 1937 onwards, one of the philosophy faculty, Fr. Gathier was in charge of the Archives. The first official archivist, Fr. Augustine Sauliere, the typist, Mr. Anthony Doss, and Fr. John Pujo, did a tremendous job in the Archives. From 1997 till 2005 many Jesuits took turns in serving at the Archives. From 2006 onwards the systematic work of scanning and computerizing the archival materials started by Fr. Joseph Antonysamy SJ. The particular portion of the building which houses the archives was constructed in 1924.  The total carpet area of the archives is about 8300 sq feet.
</p></Col>
<Col sm={4}>
    <br/>
<div class="text-center">
    <h3> Sections</h3>
    </div>

<News/>
    </Col>
  </Row>
  
  </Container>
  
  </React.Fragment>
        )
    }
}
