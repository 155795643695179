import React, { Component } from 'react';
import TableApplication from './TableApplication';

export default class ApplicationForm extends Component {
    render() {
        return (
            <React.Fragment>
                 <div style={{paddingTop:"110px"}}>
            <TableApplication/>
            </div>
            </React.Fragment>
        )
    }
}
