import React, { Component } from 'react';
import { Row,Col,Container } from 'react-bootstrap';
export default class Footer extends Component {
    render() {
        return (
            <React.Fragment>
               <div class="container-fluid Container-color">
<Container>
<Row background="black">

<Col className="Container-text">
    <span className="color-grey">
&copy;2022 </span>Jesuit Madurai  Province Archives

</Col>
<Col>
<center>
<a href="./" >
<img src="./img/arlogonew.png" width="50"  height="60"   alt="logo" ></img></a>
        </center>
</Col>
<Col>
<center>
<a href="https://www.facebook.com/Archiveshembag" target="_blank">
<img className="img-circle" src="./img/FB.png"></img></a><span className="Container-text"> Email: </span>
<a href="mailto:archiveshembag@gmail.com" className="href1"> <span className="Container-text"> archiveshembag@gmail.com </span>
</a>
</center>
  </Col>
  </Row>
</Container>
</div>
                                </React.Fragment>
        );
    }
}
