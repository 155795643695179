import React, { Component } from 'react'
import Iframe from 'react-iframe'
import { Container, Row, Col, Table } from 'reactstrap';

export default class Iframee extends Component {
    render() {
        return (
            
        <div className="container1">
    <div  className="responsive-iframe">
<Iframe url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15705.535554008202!2d77.5009738!3d10.2305902!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf3dd1af1dddcfd67!2sJesuit+Archives!5e0!3m2!1sen!2sin!4v1491980640690" 
        width="100%"
        height="450px"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"/>
        </div>
        </div> 
        
        )
    }
}
