import React, { Component } from 'react'
import Tables from './Tables'
import { Container, Row, Col, Table } from 'reactstrap';

export default class Research extends Component {
    render() {
        return (
            <React.Fragment>
                <Container>
            
  <Col sm={12}><Tables/></Col>          </Container>
            </React.Fragment>
        )
    }
}
