import React, { Component } from 'react'

export default class Login extends Component {
    render() {
        return (
            <div>
                <h1> this is from Login</h1>
            </div>
        )
    }
}
