import React from 'react';
import { Table } from 'reactstrap';
import {Link} from 'react-router-dom';

import { Container, Row, Col } from 'reactstrap';
const TableApplication= (props) => {
  return (
    <Container>
    <Table hover>
      <thead>
        <tr>
          <th>S.NO </th>
          <th>Rules </th>
              </tr>
      </thead>
      <tbody>
      <tr>
<td></td>
<td >Rules and Regulations of Jesuit Madurai Province Archives Sacred Heart College</td>
</tr>
<tr>
<td>**</td>
<td>Research in the Archives is on prior appointment only</td>
</tr>
<tr>
<td>1</td>
<td>Responsibility of a Researcher</td>
</tr>
<tr>
<td></td>
<td>a. The researcher assumes full responsibility for conforming to the laws of liable and copyright, which may be involved in his/her use of any record preserved in the archives. The researcher further agrees, by signing this application, to hold harmless the archives and its staff against all claims and actions that might arise out of his/her use of the records</td>
</tr>
<tr>
<td></td>
<td>b. Permission must be obtained to publish any records from the Jesuit Madurai Province Archives.
</td>
</tr>
<tr>
<td>2</td>
<td>Research in the Archives is allowed only for bona fide researchers, with the approval and guidance of the Director. A letter of identification is a must.</td>
</tr>
<tr>
<td>3</td>
<td>Accessibility: dates and timings</td>
</tr>
<tr>
<td></td>
<td>a. The Archive is not accessible for research during the month of May</td>
</tr>
<tr>
<td></td>
<td> b. Archives will remain open between 09.00 to 12.30 hrs and 14.00 to 17.00 hrs on working days and will remain closed on all Sundays and National holidays. </td>
</tr>
<tr>
<td>4</td>
<td>
Research scholars shall give a brief description to the Director of his/her research needs from the Archives. </td>
</tr>
<tr>
<td>5</td>
<td>Researcher may have access to books and scanned copies of manuscripts. </td>
</tr>
<tr>
 <td>6</td>
<td>Eating, drinking and smoking in the premises of Archives and taking bags and briefcases into it are not permitted. 
  </td>
</tr>
<tr>
<td>7</td>
<td>You may take paper and pencil with you into the working room. </td>
</tr>
<tr>
<td>8</td>
<td>Silence shall be maintained in the reading room. </td>
</tr>
<tr>
<td>9</td>
<td>Materials collected from our Archives are for the said purpose only. </td>
</tr>
<tr>
<td>10</td>
<td >Registration</td>
</tr>
<tr>
<td></td>
<td>a. Annual Registration fee for one year is Rs. 1000/- (One Thousand only)
</td>
</tr>
<tr>
  <td></td>
<td>b. In addition, Ph. D Scholars are to pay Rs. 1000/- as deposit and M.Phil scholars, Rs. 500/. It will be refunded when the Researcher gives a hard bound copy of the thesis to the Jesuit Madurai Province Archives. </td>
</tr>
<tr>
<td>11</td>
<td>Download your application, fill it up, affix your photo and bring it along when you come to the Archives. </td>
</tr>
<tr>
<td></td>
<td><a href='./img/Archives Application Form 2021-22.docx' download>Click here to download the applicastion form</a></td>
</tr>
<tr>
<td></td>
<td>
<td><a href='./img/Rules for Scholars in Tamil.docx' download>Click here to download Rules for Scholars in Tamil</a></td>
</td>
</tr>
</tbody>
</Table>
</Container>
  );
}


export default TableApplication;