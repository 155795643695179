import React, { Component } from 'react'
import  Couresel  from './Couresel';

export default class ImageGallery extends Component {
    render() {
        return (
            <React.Fragment >
           <Couresel />
            </React.Fragment>
        )
    }
}
