import { Switch, Route } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Component } from 'react';
import React from 'react';
import Navebar from './components/Navebar';
import Aboutus from './components/Aboutus';
import ImageGallery from './components/ImageGallery';
import Research from './components/Research';
import Materials from './components/Materials'
import ApplicationForm from './components/ApplicationForm';
import ContactUs from './components/ContactUs';
import Enquiry from './components/Enquiry';
import Login from './components/Login';
import Home from './components/Home';
import Footer from './components/Footer';
import { Container, Row, Col, Table } from 'reactstrap';
import ScrollButton from './components/ScrollButton';
import { FaAlignRight } from 'react-icons/fa';
class App extends Component{
  
  render()
  
  {
  return (
   
     <React.Fragment>
       <div>
<Navebar/>
</div>
<ScrollButton/>
<div>
<Switch>
<Route exact path="/" component={Home}/>
<Route path="/Aboutus" component={Aboutus}/>
<Route path="/ImageGallery" component={ImageGallery}/>
<Route path="/Research" component={Research}/>
<Route path="/Materials" component={Materials}/>
<Route path="/ApplicationForm" component={ApplicationForm}/>
<Route path="/ContactUs" component={ContactUs}/>
<Route path="/Enquiry" component={Enquiry}/>
<Route path="/Login" component={Login}/>
</Switch>
</div>
<Footer/>

  </React.Fragment> 
  
  );
  }
}

export default App;

