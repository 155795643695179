import React, { Component } from 'react'
import Iframee from './Iframee'
import { Container, Row, Col, Table } from 'reactstrap';


export default class ContactUs extends Component {
    render() {
        return (
            <React.Fragment>
                 <div style={{paddingTop:"110px"}}>
                <h2 style={{textAlign:"center", padding:"20px"}}> Contact Us </h2>
                 <Container>
      <Row>
        <Col sm={8}>
        
           <div className="card">
                       <Iframee/>
            </div>
                      
        </Col> 
 

<Col sm={4}> 
<Row style={{padding:"10px"}}>
<div className="card" ><b><center><u><h3>  Director </h3></u></center></b>

Director<br/>
Jesuit Madurai Province Archives <br/>
Sacred Heart College <br/>
Shembaganur <br/>
Kodaikanal - 624 101 <br/>
Email: dir.jemparc@gmail.com  <br/>
Mobile:  <br/>
</div>
</Row>
<Row style={{padding:"10px"}}>
<div  className="card" ><b><center><u><h3> Office  </h3></u></center></b>


Jesuit Madurai Province Archives <br/>
Sacred Heart College <br/>
Shembaganur <br/>
Kodaikanal - 624 101 <br/>
Email: archiveshembag@gmail.com  <br/>
Mobile:  <br/></div>
</Row>
</Col>
      </Row>
    </Container>
    </div>
            </React.Fragment>
        )
    }
}
