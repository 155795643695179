import React, { Component } from 'react';
import TablesMaterials from './TablesMaterials';

export default class Materials extends Component {
    render() {
        return (
            <React.Fragment>
                <div style={{paddingTop:"110px"}}>
            <TablesMaterials/>
            </div>
            </React.Fragment>
        )
    }
}
