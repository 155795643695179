import React, { Component } from 'react';
import { ref,Directions } from 'react-advanced-news-ticker';
import NewsTicker from "react-advanced-news-ticker";
export default class News extends Component {
    render() {
        return (
  <div id="nt-example1-container">
      
      <NewsTicker 
         ref={ref} 
         id="nt-example1" 
         direction={Directions.UP} 
         rowHeight={120} 
         maxRows={4} 
         duration={4000}>
          <div className="thumbnail"><img src=".\images\image1.JPG" alt="product" width="100px" height="60px" ></img> Anual Letters to Rome</div>
          <div className="thumbnail"><img src=".\images\image2.JPG" alt="product" width="100px" height="60px" ></img> Books in Tamil, English,Latin and French</div>
          <div className="thumbnail"><img src=".\images\image3.JPG" alt="product" width="100px" height="60px" ></img> Thousands of photos from 1860 onwards</div>
          <div className="thumbnail"><img src=".\images\image4.JPG" alt="product" width="100px" height="60px" ></img> Writings of Fr. de Rossi  (1750...)</div>
          <div className="thumbnail"><img src=".\images\image5.JPG" alt="product" width="100px" height="60px" ></img> Dictionaries in various languages</div>
          <div className="thumbnail"><img src=".\images\image6.JPG" alt="product" width="100px" height="60px" ></img> Thiru Irudaya Thoothan (1888-2021)</div>
          <div className="thumbnail"><img src=".\images\image7.JPG" alt="product" width="100px" height="60px" ></img> Writings of Veeramaminivar</div>
          <div className="thumbnail"><img src=".\images\image8.JPG" alt="product" width="100px" height="60px" ></img> Scripts in palm leaves</div>
          <div className="thumbnail"><img src=".\images\image9.JPG" alt="product" width="100px" height="60px" ></img> Caritas (1917-2017</div>
          
          
      </NewsTicker>
      
    </div>
    
        )
    }
}
