import React from 'react';
import { Table } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';

const TablesMaterials= (props) => {
  return (
    <Container>
    <Table striped>
      <thead>
        <tr>
          <th>S.No</th>
          <th>Documents / Books</th>
         
          
        </tr>
      </thead>
      <tbody>
      <tr>
<td  >1</td>

<td  >Manuscripts</td>
</tr>
<tr>
<td >2</td>

<td >Old Madurai Mission (From 1583-1774)</td>
</tr>
<tr>
<td >3</td>

<td >Parish Diaries (In the Diocese of Trichy,  Madurai, Sivagangai,  Kottar,  Palayamkottai  and  Thoothukudi)</td>
</tr>
<tr>
<td >4</td>

<td >Diaries of Persons (Jesuits – French, English, Irish, Belgian, Italian)</td>
</tr>
<tr>
<td >5</td>

<td >Parish files and other files</td>
</tr>
<tr>
<td >6</td>

<td >Shembaganur  and  Kodaikanal  (Diaries from 1895-1980)</td>
</tr>
<tr>
<td >7</td>
<td  >Photostats from Rome Photostats (letters about persons,  churches and properties)</td>
</tr>
<tr>
<td >8</td>

<td >Bishops’ Letters and Documents (A. Canoz,  J.M. Barthe,  A. Faisandier,  J.P. Leonard,     T. Roche,  Agnisamy)</td>
</tr>
<tr>
<td >9</td>

<td >Palm  Leaves  (Olaichuvadi)</td>
</tr>
<tr>
<td  >10</td>

<td  >Monographs / Studies</td>
</tr>
<tr>
<td >11</td>

<td >christianity and Church History</td>
</tr>
<tr>
<td >12</td>

<td >Protestantism</td>
</tr>
<tr>
<td >13</td>

<td >Far East</td>
</tr>
<tr>
<td >14</td>

<td >Sri Lanka</td>
</tr>
<tr>
<td >15</td>

<td >Pondicherry</td>
</tr>
<tr>
<td >16</td>

<td >Bombay, Bengal, Mangalore</td>
</tr>
<tr>
<td >17</td>

<td >Carnatic Mission</td>
</tr>
<tr>
<td >18</td>

<td >Malabar Province, Syrian christians</td>
</tr>
<tr>
<td >19</td>
<td >Mission Letters</td>
</tr>
<tr>
<td >20</td>

<td >Society of Jesus (Books,  Booklets,  Menology)</td>
</tr>
<tr>
<td >21</td>

<td >St. Ignatius of Loyola</td>
</tr>
<tr>
<td >22</td>

<td >Spiritual Exercises of St. Ignatius</td>
</tr>
<tr>
<td >23</td>

<td >The Constitutions and the Rules of the Society of Jesus</td>
</tr>
<tr>
<td >24</td>

<td >Letters of Fathers General of the SJ</td>
</tr>
<tr>
<td >25</td>

<td >General Congregation (GC) of the SJ</td>
</tr>
<tr>
<td >26</td>

<td >Reviews – Jesuit Memorabilia, Acta Romana SJ</td>
</tr>
<tr>
<td >27</td>

<td >Bibliography and Catalogue of Books</td>
</tr>
<tr>
<td >28</td>

<td >New Madurai Mission Typed Letters</td>
</tr>
<tr>
<td >29</td>

<td >New Madurai Mission</td>
</tr>
<tr>
<td >30</td>

<td >Tamil Printed Books</td>
</tr>
<tr>
<td >31</td>

<td >Tamil Manuscripts (De Rossi, De Nobili and Beschi)</td>
</tr>
<tr>
<td >32</td>

<td >Religious Congregations (Men &amp;  Women)</td>
</tr>
<tr>
<td >33</td>

<td >Jesuit Publications – English</td>
</tr>
<tr>
<td >34</td>

<td >Jesuit Publications – Tamil</td>
</tr>
<tr>
<td >35</td>

<td >French India (about Hindu,  Laws,  Culture, Customs, Castes)</td>
</tr>
<tr>
<td >36</td>

<td >Jesuit Biographies</td>
</tr>
<tr>
<td >37</td>

<td >Catalogues,  Caritas and MNL</td>
</tr>
<tr>
<td >38</td>

<td >Dictionaries (Many languages)</td>
</tr>
<tr>
<td >39</td>

<td >Mission History</td>
</tr>
<tr>
<td >40</td>

<td >Annales de la Propagation</td>
</tr>
<tr>
<td >41</td>

<td >Archivum Historicum Societatis Iesu</td>
</tr>
<tr>
<td >42</td>

<td >Monumenta Historica Societatis Iesu</td>
</tr>
<tr>
<td >43</td>

<td >Bibliotheca Instituti  Historici  Societatis Iesu</td>
</tr>
<tr>
<td >44</td>

<td >Varia – Catholic Directory</td>
</tr>
<tr>
<td >45</td>

<td >Education (Articles, Booklets, Books)</td>
</tr>
      </tbody>
    </Table>
    </Container>
  );
}

export default TablesMaterials;