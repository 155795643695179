import React from 'react';
import { Table } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';

const Tables = (props) => {
  return (
    <Container>
      <Row>
    <Table hover responsive="md">
    <thead>
        <tr>
          <th>S.No</th>
          <th>Scholar Name</th>
          <th>Title</th>
          <th>University</th>
          <th>Year</th>
          <th>Submitted</th>
        </tr>
      </thead>
      <tbody>
      <tr>
<td > 1 </td>
<td > S.Venantius Fernando </td>
<td > The Impact of the Protuguese Padroado on the Indian Pearl Fishery Coast.</td>
<td > Pontificia Universita Urbaniana,  Roma.</td>
<td > 1977</td>
<td > Phd</td>
</tr>
<tr>
<td >2</td>
<td > A. Lourdusamy</td>
<td > Thembaavaniyil Menaatu Ilakiya Thaakkam </td>
<td >Madras christytian College (MCC),  Chennai.</td>
<td >1988</td>
</tr>
<tr>
<td >3</td>
<td >K. Meenakshi</td>
<td >History of John De Britto</td>
<td >Madurai Kamaraj University (MKU), Madurai.</td>
<td >1989</td>
</tr>
<tr>
<td >4</td>
<td >G. Arokia Raj</td>
<td >Madurai Province Jesuit Archives, Shembaganur</td>
<td >MCC</td>
<td >1990</td>
</tr>
<tr>
<td >5</td>
<td >Y. Mallika</td>
<td >History of John Peter Leonard  the First Archbishop of Madurai</td>
<td >MKU</td>
<td >1990</td>
</tr>
<tr>
<td >6</td>
<td >G. Joseph Antony Samy</td>
<td >A History of Jesuits in the Madras Region  1545-1773</td>
<td >MCC</td>
<td >1990</td>
</tr>
<tr>
<td >7</td>
<td >S. M. John Kennedy</td>
<td >Ecology of Human Settlements in some Tribal  Villages</td>
<td >Pondicherry  University</td>
<td >1991</td>
</tr>
<tr>
<td >8</td>
<td >M. Elias</td>
<td >Study of Psycho – Social Correlates of Adult  learning and Development in selected Literacy centres     </td>
<td >University of  Madras.</td>
<td >1991</td>
</tr>
<tr>
<td >9</td>
<td >Rose Anne Antony</td>
<td >A study of the Management of H.S. education system  in the state of TN with Respect to the Adm. behaviour of the Heads of the  institutions </td>
<td >Sardar  Patel University,  Gujarat   </td>
<td >1992</td>
</tr>
<tr>
<td >10</td>
<td >M. Bernadette</td>
<td >Conversion in the Madura Region Since 1606 </td>
<td >MKU   </td>
<td >1992   </td>
</tr>
<tr>
<td >11</td>
<td >M. Alphonse</td>
<td >Padma Shri K.V. Peter, the Jesuit Post Master  </td>
<td >MCC</td>
<td >1993</td>
</tr>
<tr>
<td >12</td>
<td >M.A. Savariar </td>
<td >La Contribution du R. P. Jean Baptiste Trincal SJ</td>
<td >Bharathidasan  University, Tirchy.   </td>
<td >1994</td>
</tr>
<tr>
<td >13</td>
<td >S. Ebinesar</td>
<td >Kristhavargalin Kalvi Pani</td>
<td >MKU</td>
<td >1994</td>
</tr>
<tr>
<td >14</td>
<td >G. Francis Xavier Raj   </td>
<td >L’Apport Francais à la  Lexicographie Tamile  </td>
<td >Jawaharlal Nehru University, New Delhi      </td>
<td >1995</td>
</tr>
<tr>
<td >15</td>
<td >P. Theresa</td>
<td >Life and works of   Rev. Fr. J. Santiago SJ</td>
<td >MKU</td>
<td >1996</td>
</tr>
<tr>
<td >16</td>
<td >M. Sebastian </td>
<td >A Study of the Socio – Economic conditions of the  weaker sections of the society in TN</td>
<td >St. Joseph’s College (SJC), Tiruchi </td>
<td >1996</td>
</tr>
<tr>
<td >17</td>
<td >John Bosco </td>
<td >Restoration of Our Lady of  Lourdes Church, Tiruchi.</td>
<td >SJC</td>
<td >1998   </td>
</tr>
<tr>
<td >18</td>
<td >V. Alin Suneja</td>
<td >Conditions of the Sixteenth Century Tamilagam as  Revealed in the letters of    St.  Francis Xavier 1542-1552</td>
<td >MCC</td>
<td >1998</td>
</tr>
<tr>
<td >19</td>
<td >A. Joseph </td>
<td >Thembaavani- Kitheriammaal Ammaanai oru aivu</td>
<td >National college, Tiruchy</td>
<td >1999             
</td>
</tr>
<tr>
<td >20</td>
<td >A. Maria Arul Raja</td>
<td >Dalit Encounter with their suffering:   An Emancipatory interpretation of  Mark    15:1-47, from Dalit Perspective</td>
<td >University of  Madras.</td>
<td >2000</td>
</tr>
<tr>
<td >21</td>
<td >Aloysius    Idrudayam   Ajai Mathew  Naoko Yuzawa</td>
<td >Papers and Proceedings of Global conference  Against Racism and Caste Based Discrimination</td>
<td >New Delhi.</td>
<td >2001</td>
</tr>
<tr>
<td >22</td>
<td >Alphonse Manickam </td>
<td >Jesuits and Untouchability in Tamilnadu.</td>
<td >Paris.</td>
<td >2001</td>
</tr>
<tr>
<td >23</td>
<td >Aloysius Irudayam   Jayashree Mangubhai</td>
<td >Adivasi Human Rights Violations atrocities against  Adivasis in Tamilnadu</td>
<td >Village Recontruction &amp; Development project (VRDP).</td>
<td >2002</td>
</tr>
<tr>
<td >24</td>
<td >M. A. Savariar</td>
<td >The Contribution of French to the Tamil Culture  from XIX Century to XX Century</td>
<td >SJC</td>
<td >2002</td>
</tr>
<tr>
<td >25</td>
<td >S. M. John Kennedy</td>
<td >Ethnobotanical elements of the Tribals in the  Palani Hills with special Emphasis on the Traditional Medicinal Plants</td>
<td >St. Xavier’s College, Palayamkottai.</td>
<td >2003</td>
</tr>
<tr>
<td >26</td>
<td >Aloysius Irudayam </td>
<td >The right to education of Adivasi Girls</td>
<td >Society for Integrated Devp. of Tribals (SIDT)  Madurai.</td>
<td >2004</td>
</tr>
<tr>
<td >27</td>
<td >N. Shanmuga Rani</td>
<td >Viviliya Inai thirumurayil Thobithu</td>
<td >MKU</td>
<td >2004</td>
</tr>
<tr>
<td >28</td>
<td >M. Arockiasamy Xavier</td>
<td >Contribution of the Jesuits to Social Awakening in  T N</td>
<td >SJC</td>
<td >2006</td>
</tr>
<tr>
<td >29</td>
<td >P. Susai</td>
<td >Jesuit General Fr. Pedro Arrupe and his  contribution to humanism.</td>
<td >BU</td>
<td >2007</td>
</tr>
<tr>
<td >30</td>
<td >V. Sagayarasu</td>
<td >Punitha Germenammaal Aalayam Rayapuram oru aivu</td>
<td >Loyola College  Chennai.</td>
<td >2007</td>
</tr>
<tr>
<td >31</td>
<td >A. Thomas</td>
<td >Information and Communication Technology for  Empowerment of Marginal Farmers in Dindigul</td>
<td >Gandhigram Rural University. (GRU )</td>
<td >2008</td>
</tr>
<tr>
<td >32</td>
<td >Antony Raj </td>
<td >Discrimination Against Dalit christytians in Tamil  Nadu</td>
<td >Madurai.</td>
<td >  </td>
</tr>
<tr>
<td >33</td>
<td >J.Z. Antonysamy</td>
<td >The foundation of christytianity in the Nayak and  Maratha Kingdom of Thanjavur and the formation of the diocese of Thanjavur.</td>
<td >Pontificia Universita Della Santa Croce, Rome.</td>
<td >2013</td>
</tr>
<tr>
<td >34</td>
<td >S. Aruldoss</td>
<td >The First Four Jesuit Bishops and their times  (1846-1938)</td>
<td >Bishop Heber College, Tiruchi.</td>
<td >2013</td>
</tr>
<tr>
<td >35</td>
<td >Anthony Muthu</td>
<td >History of Tiruchirapplli Diocese and its Varied  Roles</td>
<td >MKU</td>
<td >1995  </td>
</tr>
<tr>
<td >36</td>
<td >C. Thanga Thilagavathi </td>
<td >Thoobanjali oru aivu</td>
<td >MKU</td>
<td >      </td>
</tr>
      </tbody>
    </Table>
    </Row>
    </Container>
  );
}

export default Tables;